@font-face {
  font-family: "Open Sans";
  src: url("/src/fonts/cinzel.ttf");
}

:root {
  --header-height: 100px;

  @media screen and (max-width: 768px) {
    --header-height: 50px;
  }
}

a {
  text-decoration: none;
  color: white;
}

h1 {
  margin: 0;
}

body {
  color: white;
  font-family: "Open Sans", sans-serif;
  background-color: black;
  margin: 0;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.header {
  height: var(--header-height);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0;
    background-color: #fff;
  }

  .navbar {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    .nav-item {
      font-size: 20px;
      text-transform: uppercase;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.5s ease, color 0.5s ease;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        font-size: 12px;
      }

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }
}

main {
  width: 100%;
}

.transition {
  width: 100%;
  height: 100vh;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 484;
}

.left-transition {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 484;
  transform: scaleX(0);
}

.right-transition {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 484;
  transform: scaleX(0);
}

.page-title {
  text-align: center;
  width: 100%;
  font-size: 50px;
  font-weight: 400;
  padding: 30px 0;

  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
}

.cluster-title {
  text-align: right;
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  margin: 0;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
}

.cluster-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  justify-content: space-evenly;
}

.cluster-description {
  width: 70%;
  margin-top: 100px;
  font-size: 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
    font-size: 12px;
  }
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.first-section {
  margin-top: calc(var(--header-height));
}

.stories-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  padding: 20px;
  perspective: 3000px;

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    perspective: 10000px;
  }
}

.stories-list-item {
  width: 100%;
  padding-top: 177.77%;
  position: relative;
  border: 1px solid #fff;
  transition: transform 1s;
  transform-style: preserve-3d;
  pointer-events: all;
  cursor: pointer;
}

.storie-img-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-perspective: 0;
  -webkit-backface-visibility: hidden;
  visibility: visible;
  backface-visibility: hidden;
}

.storie-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.stories-list-item.is-flipped {
  transform: rotateY(180deg);
}

.card-face-back {
  transform: rotateY(180deg);
  background-color: black;
}

.card-face-front.card-face {
  justify-content: center;
}

.card-face-front {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000000;
    z-index: 3;
    transition: background-color 0.5s ease-in-out;
  }

  @media screen and (max-width: 768px) {
    &::after {
      background-color: #000000b9;
    }

    .front-description-title {
      opacity: 1;
    }

    .front-description-text {
      opacity: 1;
    }

    &:hover {
      &::after {
        background-color: none;
      }

      .front-description-title {
        opacity: none;
      }

      .front-description-text {
        opacity: none;
      }
    }
  }

  &:hover {
    &::after {
      background-color: #000000b9;
    }

    .front-description-title {
      opacity: 1;
    }

    .front-description-text {
      opacity: 1;
    }
  }
}

.card-face {
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  font-weight: 400;
  -webkit-perspective: 0;
  -webkit-backface-visibility: hidden;
  visibility: visible;
  backface-visibility: hidden;
  align-items: center;
}

.front-description-title {
  font-size: 1.5vw;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  position: relative;
  z-index: 4;
  padding: 20px;

  @media screen and (max-width: 768px) {
    font-size: 7.5vw;
  }
}

.front-description-text {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 4;
  position: relative;
  padding: 20px;
  font-size: 13px;
}

.back-description-title {
  font-size: 1.5vw;
  opacity: 1;
  position: relative;
  z-index: 4;
  padding: 20px;

  @media screen and (max-width: 768px) {
    font-size: 6vw;
  }
}

.back-description-text {
  opacity: 1;
  z-index: 4;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 0.7vw;

  @media screen and (max-width: 768px) {
    font-size: 4vw;
  }
}

.back-players-count {
  padding: 20px;
}

//Календарь

.calendar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  flex-wrap: wrap;
  list-style: none;
  width: min-content;
  padding: 0;

  .day {
    display: flex;
    width: 10rem;
    height: 10rem;
    margin: 4px;
    flex-flow: column;
    border-radius: 0.2rem;
    padding: 1rem;
    font-size: 0.8rem;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid rgb(255, 255, 255);

    @media screen and (max-width: 1280px) {
      width: 6rem;
      height: 6rem;
    }

    @media screen and (max-width: 768px) {
      width: 2.7rem;
      height: 2.7rem;
      padding: 0.5rem;
      border-radius: 4px;
    }

    time {
      font-size: 32px;
      margin: 0 0 1rem 0;

      @media screen and (max-width: 1280px) {
        font-size: 16px;
      }

      @media screen and (max-width: 768px) {
        font-size: 8px;
        margin: 0 0 0.1rem 0;
      }
    }
  }

  .today {
    background: #ffffff70;
  }
}

.day-time {
  font-size: 19px;
  position: relative;

  @media screen and (max-width: 1280px) {
    font-size: 13px;
  }

  @media screen and (max-width: 768px) {
    font-size: 6.5px;
  }
}

.day-time.busy {
  &::after {
    content: "";
    width: 45%;
    height: 3px;
    background-color: #5f0202;
    position: absolute;
    left: -2%;
    top: 50%;

    @media screen and (max-width: 768px) {
      height: 2px;
      width: 80%;
    }
  }
}

.week-day {
  font-size: 16px;

  @media screen and (max-width: 1280px) {
    font-size: 9.5px;
  }

  @media screen and (max-width: 768px) {
    font-size: 6.5px;
  }
}

.day.empty {
  visibility: hidden;
}

.calendar-section {
  display: flex;
  align-items: center;
}

.calendar-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev-month-button,
.next-month-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #000;
  border: 1px solid #fff;
  margin: 20px;
  transition: background-color 0.5s ease, stroke 0.5s ease, transform 0.5s ease;
  cursor: pointer;

  &:hover {
    background-color: #fff;

    path {
      stroke: #000;
    }
  }

  &:active {
    background-color: #000;

    path {
      stroke: #fff;
    }
  }
}

.next-month-button {
  svg {
    transform: rotate(180deg);
  }
}

.current-month {
  min-width: 200px;
  text-align: center;
}

@keyframes rotate {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }

  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}

.intro-text {
  padding: 0 80px;
}

.cluster-list {
  perspective: 900px;
  padding-left: 0;
  top: 10px;
  margin: 0;
}

.cluster-item {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: #000;
  border: 1px solid #fff;
  padding: 30px;
  margin-top: 30px;
  will-change: transform;
  min-height: 50vh;
  justify-content: flex-start;
  position: sticky;

  @media screen and (max-width: 768px) {
    min-height: unset;
  }

  &:nth-child(odd) {
    align-items: flex-end;

    .cluster-title {
      text-align: right;

      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }

    .logo-wrapper {
      position: absolute;
      height: 100%;
      width: auto;
      left: 0;
      top: 50%;
      transform: translate(-50%, 0);

      @media screen and (max-width: 768px) {
        opacity: 0.1;
        width: 100%;
        height: auto;
        left: 50%;
        top: 0%;
      }
    }
  }

  &:nth-child(even) {
    align-items: flex-start;

    .cluster-title {
      text-align: left;

      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }

    .logo-wrapper {
      position: absolute;
      height: 100%;
      width: auto;
      right: 0;
      top: 50%;
      transform: translate(50%, 0);

      @media screen and (max-width: 768px) {
        opacity: 0.1;
        width: 100%;
        height: auto;
        right: 50%;
        top: 0%;
      }
    }
  }

  &:nth-child(1) {
    top: 0;
  }

  @for $i from 2 through 3 {
    &:nth-child(#{$i}n) {
      top: ($i - 1) * 20vh;
    }
  }

  @media screen and (max-width: 768px) {
    @for $i from 2 through 3 {
      &:nth-child(#{$i}n) {
        top: ($i - 1) * 14vh;
      }
    }
  }
}

.cluster-item-bg-layer {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: black;
  height: 100%;
  width: 100%;
  opacity: 0;
  will-change: opacity;
}

.cluster-item.contacts-card {
  min-height: 25vh;
  align-items: center;
  justify-content: start;

  @media screen and (max-width: 768px) {
    min-height: unset;
  }

  .cluster-title {
    text-align: center;
  }

  .contacts-items {
    display: flex;
    margin-top: 40px;
    gap: 40px;
    z-index: 1;
  }

  .contacts-item {
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  .logo-wrapper:nth-child(1) {
    height: 200%;
    left: 0;
    right: unset;
    transform: translate(-50%, 25%);

    @media screen and (max-width: 768px) {
      width: auto;
      left: 50%;
    }

    .logo {
      @media screen and (max-width: 768px) {
        width: auto;
      }
    }
  }

  .logo-wrapper:nth-child(2) {
    right: 0;
    left: unset;
    height: 200%;
    transform: translate(50%, 25%);

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

.logo {
  pointer-events: none;
  width: 100%;
  height: 100%;
  animation: rotate 30s linear infinite;
}

.home-title {
  padding-top: 60px;
}
